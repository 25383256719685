<template>
    <div class="max-width white-back" style="margin: 30px auto;padding: 30px;">
        <div v-html="$store.state.bottomInfo.mianze"></div>
    </div>
</template>

<script>
    export default {
        name: "disclaimers"
    }
</script>

<style scoped>
    h2{margin: 10px 0;font-size: 16px;}
p {text-indent: 50px;line-height: 20px;letter-spacing:2px;font-size: 14px;}
</style>
